import React from 'react';
import { GetApp } from '@material-ui/icons';

import styles from '../MediaViewer.module.css';

// eslint-disable-next-line react/prop-types
export default function MediaViewerImage({ filename, downloadURL }) {
  return (
    <section className={styles.image}>
      <div className={styles.nav}>
        <p>
          {filename}
        </p>
        <a className={styles.download} href={downloadURL} aria-label="Download" download>
          <GetApp />
        </a>
      </div>
      <main className={styles.main}>
        <img
          src={downloadURL}
          alt={filename}
        />
      </main>
    </section>
  );
}
